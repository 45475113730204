import {Alert, Button, Form} from 'react-bootstrap';
import {useState, useRef} from 'react';

function AdminStartGame({adminToken, gameStarted}) {
  const [gameTitle, setGameTitle] = useState('');
  const [teams, setTeams] = useState([]);
  const [startError, setStartError] = useState(undefined);
  const formRef = useRef(null);

  const handleTeamsChange = (index, teamId, teamName) => {
    const updatedTeams = [...teams];
    if (index < updatedTeams.length) {
      updatedTeams[index] = {teamId, teamName};
    } else {
      updatedTeams.push({teamId, teamName});
    }

    setTeams(updatedTeams.filter((team) => !!team.teamId || !!team.teamName));
  };

  const startGame = (e) => {
    if (!formRef.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    fetch('/api/start', {
      body: JSON.stringify({
        settings: {
          gameTitle,
          teams,
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-AdminToken': adminToken,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          gameStarted();
        } else {
          setStartError({
            error: 'invalid server response',
            code: res.status,
            message: res.body,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setStartError({
          error: 'failed to start',
          message: err,
        });
      });
  };

  const renderTeams = [...teams];
  renderTeams.push({teamId: '', teamName: ''});

  return (
    <div className="admin-start-game">
      {startError ? (
        <Alert variant="danger">
          <Alert.Heading>Cannot start game</Alert.Heading>
          <p>{JSON.stringify(startError)}</p>
        </Alert>
      ) : null}
      <Form ref={formRef}>
        <Form.Group controlId="formGameTitle">
          <Form.Label>Titolo del gioco</Form.Label>
          <Form.Control
            type="text"
            value={gameTitle}
            onChange={(e) => setGameTitle(e.target.value)}
            required={true}
          />
        </Form.Group>

        <div className="start-game-teams">
          <span>Elenco Squadre</span>
          <div className="container">
            {renderTeams.map((team, index) => (
              <div key={index} className="row">
                <Form.Group controlId={`teamId${index}`} className="col-6">
                  <Form.Label>ID Team</Form.Label>
                  <Form.Control
                    type="text"
                    value={team.teamId}
                    onChange={(e) =>
                      handleTeamsChange(index, e.target.value, team.teamName)
                    }
                    required={index < renderTeams.length - 1}
                  />
                </Form.Group>
                <Form.Group controlId={`teamName${index}`} className="col-6">
                  <Form.Label>Nome Team</Form.Label>
                  <Form.Control
                    type="text"
                    value={team.teamName}
                    onChange={(e) =>
                      handleTeamsChange(index, team.teamId, e.target.value)
                    }
                    required={index < renderTeams.length - 1}
                  />
                </Form.Group>
              </div>
            ))}
          </div>
        </div>

        <div className="start-game-control">
        <Button variant="primary" type="submit" onClick={startGame}>
          Avvia gioco
        </Button>
        </div>
      </Form>
    </div>
  );
}

export default AdminStartGame;
