import {Alert, Button, Table} from 'react-bootstrap';
import {useState, useEffect, useRef} from 'react';
import {createWebSocket} from '../utils/helper';

function AdminBoard({adminToken, refreshStatus}) {
  const [status, setStatus] = useState(undefined);
  const [endError, setEndError] = useState(undefined);
  const sendMessage = useRef((msg) => {});

  useEffect(() => {
    const socket = createWebSocket();

    socket.onopen = () => {
      sendMessage.current = (message) => socket.send(JSON.stringify(message));

      sendMessage.current({
        kind: 'adminjoinrequest',
        value: {
          token: adminToken,
        },
      });
    };

    socket.onmessage = (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.event) {
        case 'status':
          setStatus(message.status);
          break;
        default:
          console.error(`unknown event: ${message.event}`);
      }
    };

    return () => {
      socket.close();
    };
  }, [adminToken]);

  const disconnectPlayers = () => {
    sendMessage.current({
      kind: 'disconnectplayers',
    });
  };

  const endGame = () => {
    fetch('/api/terminate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-AdminToken': adminToken,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          refreshStatus();
        } else {
          setEndError({
            error: 'invalid server response',
            code: res.status,
            message: res.body,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setEndError({
          error: 'failed to terminate game',
          message: err,
        });
      });
  };

  const setQuestion = () => {
    sendMessage.current({
      kind: 'setquestion',
      value: {
        kind: 'booking',
        date: new Date().getTime(),
      },
    });
  };

  const unsetQuestion = () => {
    sendMessage.current({
      kind: 'unsetquestion',
    });
  };

  return (
    <div className="admin-board">
      {status ? (
        <>
          {endError ? (
            <Alert variant="danger">
              <Alert.Heading>Impossibile terminare la partita</Alert.Heading>
              <p>{endError}</p>
            </Alert>
          ) : null}
          <header className="board-header">
            <span>Titolo gioco: {status.settings.gameTitle}</span>
          </header>
          <div className="container">
            <div className="row g-3">
              <section className="col-md-9">
                <div className="board-cell">
                  <header>Squadre e players</header>
                  <div className="cell-content">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Team ID</th>
                          <th>Team Name</th>
                          <th>Players</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {status.settings.teams.map((team, index) => (
                          <tr key={'team' + index}>
                            <td>{team.teamId}</td>
                            <td>{team.teamName}</td>
                            <td>
                              {status.players
                                .filter((p) => p.team.teamId === team.teamId)
                                .map((p) => p.username)
                                .join(', ')}
                            </td>
                            <td><a href={'/' + team.teamToken} target="_blank" rel="noreferrer">link</a></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </section>

              <section className="col-md-3">
                <div className="board-cell">
                  <header>Gestione partita</header>
                  <div className="cell-content manage-game">
                    <Button variant="secondary" onClick={disconnectPlayers}>
                      Disconnetti players
                    </Button>
                    <Button variant="danger" onClick={endGame}>
                      Termina partita
                    </Button>
                  </div>
                </div>
              </section>
            </div>

            <div className="row g-3">
              <section className="col-md-6">
                <div className="board-cell">
                  <header>Domanda</header>
                  <div className="cell-content question">
                    <span>
                      {status.question
                        ? 'Domanda di prenotazione creata alle ' + new Date(status.question.date).toLocaleTimeString()
                        : 'Nessuna domanda in corso'}
                    </span>

                    <Button variant="success" onClick={setQuestion}>
                      Nuova domanda di prenotazione
                    </Button>

                    {status.question ? (
                      <Button variant="secondary" onClick={unsetQuestion}>
                        Cancella domanda corrente
                      </Button>
                    ) : null}
                  </div>
                </div>
              </section>

              <section className="col-md-6">
                <div className="board-cell">
                  <header>Risposte</header>
                  <div className="cell-content">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Team Name</th>
                          <th>Player</th>
                          <th>Orario</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(status.answers || []).map((answer, index) => (
                          <tr key={'answer' + index}>
                            <td>{index + 1}.</td>
                            <td>{answer.player.team.teamName}</td>
                            <td>{answer.player.username}</td>
                            <td>{new Date(answer.answer.date).toLocaleTimeString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <span>Connessione all'admin board..</span>
      )}
    </div>
  );
}

export default AdminBoard;
