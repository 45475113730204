import logo from '../assets/logo.webp';

import AdminBoard from './AdminBoard';
import AdminStartGame from './AdminStartGame';
import GameBoard from './GameBoard';

import {useEffect, useState} from 'react';

function MainPage() {
  const [status, setStatus] = useState(undefined);
  const [token, setToken] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);

  const getStatus = () => {
    fetch('/api/status', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status !== 200) {
        setStatus({
          error: 'failed to fetch status',
          code: res.status,
        });

        return;
      }

      res
        .json()
        .then((body) => {
          setStatus(body);
        })
        .catch((err) => {
          setStatus({
            error: 'failed to parse status',
            code: res.status,
            message: err,
          });
        });
    });
  };

  const getUrlParams = () => {
    const matchUrl = document.location.pathname.match(
      /^\/(admin\/)?([\w-]+\.[\w-]+\.[\w-]+)$/
    );

    if (matchUrl) {
      if (matchUrl[1]) {
        setIsAdmin(true);
      }
      setToken(matchUrl[2]);
    }

    window.history.pushState('', '', '/');
  };

  useEffect(() => {
    getStatus();
    getUrlParams();
  }, []);

  const loadMain = () => {
    if (status) {
      if (status.active) {
        if (isAdmin) {
          return <AdminBoard status={status} adminToken={token} refreshStatus={getStatus}></AdminBoard>;
        }

        return <GameBoard initialStatus={status} token={token} setToken={setToken}></GameBoard>;
      } else {
        if (isAdmin) {
          return <AdminStartGame adminToken={token} gameStarted={getStatus} />;
        }

        return <div>Nessuna partita in corso..</div>;
      }
    } else {
      return <div>Caricamento..</div>;
    }
  };

  return (
    <div className="main">
      <header className="app-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content">{loadMain()}</div>
      <footer className="app-footer">
        Realizzato (a malavoglia) da Emiliano
      </footer>
    </div>
  );
}

export default MainPage;
