import {Alert, Button, Form} from 'react-bootstrap';
import {useRef, useState} from 'react';

function JoinGame({joinGame, joinError}) {
  const formRef = useRef(null);
  const [username, setUsername] = useState('');

  const startGame = (e) => {
    if (!formRef.current.checkValidity()) {
      return;
    }

    e.preventDefault();
    joinGame(username);
  }

  return (
    <div className="join-game">
      {joinError ? (
        <Alert variant="danger">
          <Alert.Heading>Impossibile entrare in partita</Alert.Heading>
          <p>{joinError}</p>
        </Alert>
      ) : null}

      <Form ref={formRef}>
        <Form.Group controlId="formUsername">
          <Form.Label>Inserisci il tuo nome</Form.Label>
          <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required={true} />
        </Form.Group>

        <div className="join-control">
          <Button variant="primary" type="submit" onClick={startGame}>
            Avvia gioco
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default JoinGame;
