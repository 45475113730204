import {ListGroup} from 'react-bootstrap';
import {useEffect, useState, useRef} from 'react';
import JoinGame from './JoinGame';
import lightOff from '../assets/light-off.svg';
import lightOn from '../assets/light-on.svg';
import {createWebSocket} from '../utils/helper';

function GameBoard({initialStatus, token, setToken}) {
  const [username, setUsername] = useState(undefined);
  const [joinError, setJoinError] = useState(undefined);
  const [status, setStatus] = useState(initialStatus);
  const [disconnected, setDisconnected] = useState(false);
  const [retry, setRetry] = useState(0);
  const sendMessage = useRef((msg) => {});

  useEffect(() => {
    if (!username || !token || disconnected) {
      return;
    }

    const socket = createWebSocket();

    socket.onopen = () => {
      console.log('Connected');
      sendMessage.current = (message) => socket.send(JSON.stringify(message));

      sendMessage.current({
        kind: 'joinrequest',
        value: {
          username,
          token,
        },
      });
    };

    socket.onmessage = (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.event) {
        case 'status':
          setStatus(message.status);
          break;
        case 'joinrequest':
          if (message.error) {
            setUsername(undefined);
            setJoinError(message.error);
          }
          break;
        case 'disconnected':
          setDisconnected(true);
          break;
        default:
          console.error(`unknown event: ${message}`);
      }
    };

    socket.onclose = () => {
      setTimeout(() => setRetry(retry + 1), 1000);
    };

    return () => {
      socket.close();
    };
  }, [username, token, retry, disconnected]);

  const bookAnswer = (disabled) => {
    if (disabled) {
      return;
    }

    sendMessage.current({
      kind: 'answer',
      value: {
        kind: 'booking',
        date: new Date().getTime(),
      },
    });
  };

  const getReservations = () => {
    const reservation = [];

    for (const answer of status.answers) {
      if (!reservation.includes(answer.player.team.teamName)) {
        reservation.push(answer.player.team.teamName);
      }
    }

    return reservation.slice(0, 3);
  };

  const getBoard = () => {
    if (disconnected) {
      return <>La partita è terminata</>;
    }

    if (!token) {
      return <>Inquadra un QR Code per giocare</>;
    }

    if (!username) {
      return <JoinGame joinGame={setUsername} joinError={joinError} />;
    }

    if (!status.question) {
      return <>Attendi la prossima domanda..</>;
    }

    const disabled = !!status.answers.find((answer) => answer.player.username === username);

    return (
      <>
        <div className="question">
          <img src={disabled ? lightOn : lightOff} onClick={() => bookAnswer(disabled)} alt="Prenotati" />
        </div>

        <div className="answers">
          {status.answers.length > 0 ? (
            <>
              <header>
                <span>Team prenotati</span>
              </header>
              <div className="reservations">
                <ListGroup numbered>
                  {getReservations().map((team, index) => (
                    <ListGroup.Item key={'reservation' + index}>{team}</ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </>
          ) : (
            <>Nessuna prenotazione</>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="game-board">
      <header className="game-title">
        <span>{status.gameTitle || status.settings?.gameTitle}</span>
      </header>
      <div className="game-table">{getBoard()}</div>
    </div>
  );
}

export default GameBoard;
