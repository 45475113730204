import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import MainPage from './components/MainPage';


function App() {
  return <div className="app"><MainPage /></div>;
}

export default App;
